import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'

const AreasServedContainer = styled.div`
  h1 {
    font-size: calc(0.75vw + 1.5em);
  }
`

export const AreasServed = (props) => {
  const {
    citiesList,
    className,
    serviceName = '',
    parentPageSlug,
    blogComponent,
  } = props

  const getServiceTitle = () => {
    if (serviceName.includes('Residential Electrical Contractor'))
      return 'Residential Electrician'
    if (serviceName.includes('Electrical Outlet & Switches Installation'))
      return 'Electrical Outlet & Switches Services'
    if (serviceName.includes('Electrical Panel'))
      return 'Electrical Panel Services'
  }

  const getServiceSlug = () => {
    if (serviceName.includes('Residential Electrical Contractor'))
      return 'electrician'
    if (serviceName.includes('Outlet & Switches'))
      return 'electrical-outlet-and-switches'
    if (serviceName.includes('Electrical Panel'))
      return 'electrical-panel-service'
  }

  const serviceTitle = getServiceTitle()
  const serviceSlug = getServiceSlug()

  return (
    <AreasServedContainer className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <div className="container is-service-page">
        <div className="pt-8 pb-5">
          <h2>Areas Served</h2>
          {!blogComponent ? (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map(
                (item, i) =>
                  serviceName.includes('Siding') && (
                    <li key={i} className="w-1/2 md:w-1/4 flex">
                      <div className="flex-shrink-0 pr-1">
                        <svg
                          className="h-6 w-6 text-green-500"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </div>{' '}
                      <Link
                        className="text-gray-800 hover:text-brand-600"
                        title={`${
                          item.node.location +
                          ' ' +
                          (serviceTitle ? serviceTitle + ' ' : '')
                        }`}
                        to={`/${serviceSlug ? serviceSlug : ''}-${
                          item.node.slug + '/'
                        }`}
                      >
                        {item.node.location}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          ) : (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg
                      className="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>{' '}
                  <Link
                    className="text-gray-800 hover:text-brand-600"
                    title={`${
                      item.location + ' ' + (serviceTitle ? serviceTitle : '')
                    }`}
                    to={`/${serviceSlug ? serviceSlug : ''}-${item.slug + '/'}`}
                  >
                    {item.location}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </AreasServedContainer>
  )
}
